import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllCitiesService, getAllGovernoratesService } from '../../../services/lookup.service'
import { IPagination } from '../../../utility/interface/pagination.interface'

export const getAllGovernoratesAction = createAsyncThunk('getAllGovernoratesAction/get', async (query: IPagination, thunkApi) => {
	try {
		const response = await getAllGovernoratesService(query)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getAllCitiesAction = createAsyncThunk('getAllCitiesAction/get', async (id:string, thunkApi) => {
	try {
		const response = await getAllCitiesService(id)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
