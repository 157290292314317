// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
	window.location.hostname === '[::1]' ||
	window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

type Config = {
	onSuccess?: (registration: ServiceWorkerRegistration) => void
	onUpdate?: (registration: ServiceWorkerRegistration) => void
}

export const register = (config?: Config): void => {
	if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
		const publicUrl = new URL(process.env.REACT_APP_ASSETS_URL!, window.location.href)
		if (publicUrl.origin !== window.location.origin) {
			return
		}
		window.addEventListener('load', () => {
			const swUrl = `${process.env.REACT_APP_ASSETS_URL}/service-worker.js`
			if (isLocalhost) {
				checkValidServiceWorker(swUrl, config)
				navigator.serviceWorker.ready
			} else {
				registerValidSW(swUrl, config)
			}
		})
	}
}

function registerValidSW(swUrl: string, config?: Config) {
	navigator.serviceWorker
		.register(swUrl)
		.then((registration) => {
			registration.onupdatefound = () => {
				const installingWorker = registration.installing
				if (installingWorker == null) {
					return
				}
				installingWorker.onstatechange = () => {
					if (installingWorker.state === 'installed') {
						if (navigator.serviceWorker.controller) {
							if (config && config.onUpdate) {
								config.onUpdate(registration)
							}
						} else {
							if (config && config.onSuccess) {
								config.onSuccess(registration)
							}
						}
					}
				}
			}
		})
		.catch((error) => {
			console.error('Error during service worker registration:', error)
		})
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
	fetch(swUrl, {
		headers: { 'Service-Worker': 'script' }
	})
		.then((response) => {
			const contentType = response.headers.get('content-type')
			if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
				navigator.serviceWorker.ready.then((registration) => {
					registration.unregister().then(() => {
						window.location.reload()
					})
				})
			} else {
				registerValidSW(swUrl, config)
			}
		})
		.catch(() => { })
}

export const unregister = (): void => {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready
			.then((registration) => {
				registration.unregister()
			})
			.catch((error) => {
				console.error(error.message)
			})
	}
}
