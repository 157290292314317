import { createSlice } from '@reduxjs/toolkit'
import { getAllProductsAction, getFilterCategoriesAction, getProductDetailsAction } from '../actions/menu.action'
import { IPagination } from '../../../utility/interface/pagination.interface'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import { ICategory } from '../../../common/lookups/interfaces/category.interface'
import { IProduct } from '../../../common/interfaces/product.interface'

interface DataReducer {
	products: {
		data: Array<IProduct>
		status: REDUX_STATUS
		query: IPagination
		_metadata: {
			totalPage: number
			total: number
		}
	}
	productDetails: IProduct
	categories: {
		data: Array<ICategory>
		status: REDUX_STATUS
		query: IPagination
	}
}

const initialState: DataReducer = {
	products: {
		data: [],
		status: REDUX_STATUS.IDLE,
		query: {
			page: 1,
			perPage: 12
		},
		_metadata: {
			totalPage: 0,
			total: 0
		}
	},
	productDetails: {} as IProduct,
	categories: {
		data: [],
		status: REDUX_STATUS.IDLE,
		query: {
			page: 1,
			perPage: 12
		}
	}
}

const menuSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setProductsQuery: (state, action) => {
			state.products.query = action.payload
		},
		setProductsStatus: (state, action) => {
			state.products.status = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllProductsAction.pending, (state) => {
				state.products.status = REDUX_STATUS.PENDING
			})
			.addCase(getAllProductsAction.fulfilled, (state, action) => {
				state.products.data = action.payload.data
				state.products._metadata = { totalPage: action.payload._metadata.pagination.totalPage, total: action.payload._metadata.pagination.total }
				state.products.status = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getAllProductsAction.rejected, (state) => {
				state.products.status = REDUX_STATUS.FAILED
			})

			.addCase(getProductDetailsAction.pending, () => {})
			.addCase(getProductDetailsAction.fulfilled, (state, action) => {
				state.productDetails = action.payload.data
			})
			.addCase(getProductDetailsAction.rejected, () => {})

			.addCase(getFilterCategoriesAction.pending, (state) => {
				state.categories.status = REDUX_STATUS.PENDING
			})
			.addCase(getFilterCategoriesAction.fulfilled, (state, action) => {
				state.categories.data = action.payload.data
				state.categories.status = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getFilterCategoriesAction.rejected, (state) => {
				state.categories.status = REDUX_STATUS.FAILED
			})
	}
})

export const menuActions = menuSlice.actions
export default menuSlice.reducer
