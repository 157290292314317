import { createAsyncThunk } from "@reduxjs/toolkit"
import { addKitchenReviewService, getAllKitchensService, getKitchenDetailsService, getKitchenReviewsService } from "../../../services/kitchen.service"
import { IPagination } from "../../../utility/interface/pagination.interface"
import { IReviewDto } from "../interfaces/review.interface"

export const getAllKitchensAction = createAsyncThunk('getAllKitchensAction/post', async (data: IPagination, thunkApi) => {
	try {
		const response = await getAllKitchensService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getKitchenDetailsAction = createAsyncThunk('getKitchenDetailsAction/get', async (id: string, thunkApi) => {
	try {
		const response = await getKitchenDetailsService(id)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getKitchenReviewsAction = createAsyncThunk('getKitchenReviewsAction/get', async (id: string, thunkApi) => {
	try {
		const response = await getKitchenReviewsService(id)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const addKitchenReviewAction = createAsyncThunk('addKitchenReviewAction/get', async (data: IReviewDto, thunkApi) => {
	try {
		const response = await addKitchenReviewService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})