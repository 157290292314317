import { createAsyncThunk } from '@reduxjs/toolkit'
import { addCartItemService, addNewCartService, getCartService, removeCartItemService, updateCartItemService } from '../../../services/cart.service'
import { CartRemoveDto, CartUpdateDto } from '../../../common/components/interfaces/cart.interface'

export const getCartAction = createAsyncThunk('getCartAction/get', async (id: string, thunkApi) => {
	try {
		const response = await getCartService(id)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const addNewCartAction = createAsyncThunk('addNewCartAction/post', async (data: CartUpdateDto, thunkApi) => {
	try {
		const response = await addNewCartService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const addCartItemAction = createAsyncThunk('addCartItemAction/put', async (update: { id: string; data: CartUpdateDto }, thunkApi) => {
	try {
		const response = await addCartItemService(update.id, update.data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const removeCartItemAction = createAsyncThunk('removeCartItemAction/put', async (update: { id: string; data: CartRemoveDto }, thunkApi) => {
	try {
		const response = await removeCartItemService(update.id, update.data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const updateCartItemAction = createAsyncThunk('updateCartItemAction/post', async (update: { id: string; data: any }, thunkApi) => {
	try {
		const response = await updateCartItemService(update.id, update.data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
