import React, { createContext, useEffect } from 'react';
import { IAuth } from '../modules/Auth/interface/Auth.interface';
import { getProfileAction } from '../modules/profile/action/profile.action';
import { useAppSelector, useAppDispatch } from '../store/store';
import { authActions } from '../modules/Auth/slice/auth.slice';

interface InitialAuth {
	auth: IAuth | any
}

const initValue: InitialAuth = {
	auth: null
}

const AuthContext = createContext(initValue)

export const AuthProvider = ({ children }: any) => {
	const authState = useAppSelector((state: any) => state.auth.contents);
	const authStorage: IAuth = JSON.parse(localStorage.getItem('TK')!);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!authState && authStorage) {
			dispatch(authActions.setLogin(authStorage))
		}
	}, [dispatch, authState, authStorage])

	useEffect(() => {
		if (authState && authStorage) {
			dispatch(getProfileAction())
		}
	}, [dispatch, authState, authStorage])

	return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
}

export default AuthContext
