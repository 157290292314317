import { Api } from '../../../resources/api-constants';
import customAxios from '../../../utility/customAxios';
import { PaginationExtractor } from '../../../utility/helpers/pagination-extractor';
import { IPagination } from '../../../utility/interface/pagination.interface';

export const getAllProductsService = (query: IPagination) => {
	return customAxios.get(`${Api.GET_ALL_PRODUCTS}${PaginationExtractor(query)}`);
};

export const getProductDetailsService = (id: string) => {
	return customAxios.get(`${Api.GET_PRODUCT_DETAILS}/${id}`);
};

export const getFilterCategoriesService = (query: IPagination) => {
  return customAxios.get(`${Api.LOOKUP_CATEGORIES}${PaginationExtractor(query)}`);
};