import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IAuth } from '../interface/Auth.interface'
import { ErrorResponse } from '../../../utility/interface/ErrorResponse.interface'
import { loginAction, signupAction, validateUserLoginAction } from '../actions/auth.action'
import customAxios from '../../../utility/customAxios'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const SweetAlert = withReactContent(Swal)

interface DataReducer {
	contents: IAuth | null
	status: REDUX_STATUS
	error: ErrorResponse | null
}

const initialState: DataReducer = {
	contents: null,
	status: REDUX_STATUS.IDLE,
	error: null
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setLogin: (state: any, action: PayloadAction<any>) => {
			state.contents = action.payload
		},
		setLogout: (state: any, action: PayloadAction<any>) => {
			state.contents = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING
			})
			.addCase(loginAction.fulfilled, (state, action: PayloadAction<any>) => {
				state.contents = action.payload.data
				state.status = REDUX_STATUS.SUCCEEDED
				customAxios.interceptors.request.use(
					async (config) => {
						config.headers['Authorization'] = `Bearer ${action.payload.data.accessToken}`
						return config
					},
					(error) => {
						return Promise.reject(error)
					}
				)
				localStorage.setItem('TK', JSON.stringify(action.payload.data))
			})
			.addCase(loginAction.rejected, (state, action: PayloadAction<any>) => {
				state.status = REDUX_STATUS.FAILED
				state.error = action.payload
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
			
			.addCase(validateUserLoginAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING
			})
			.addCase(validateUserLoginAction.fulfilled, (state, action: PayloadAction<any>) => {
				state.contents = action.payload.data
				state.status = REDUX_STATUS.SUCCEEDED
				customAxios.interceptors.request.use(
					async (config) => {
						config.headers['Authorization'] = `Bearer ${action.payload.data.accessToken}`
						return config
					},
					(error) => {
						return Promise.reject(error)
					}
				)
				localStorage.setItem('TK', JSON.stringify(action.payload.data))
			})
			.addCase(validateUserLoginAction.rejected, (state, action: PayloadAction<any>) => {
				state.status = REDUX_STATUS.FAILED
				state.error = action.payload
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})

			.addCase(signupAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING
			})
			.addCase(signupAction.fulfilled, (state, action: PayloadAction<any>) => {
				state.status = REDUX_STATUS.SUCCEEDED
				SweetAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
				customAxios.interceptors.request.use(
					async (config) => {
						config.headers['Authorization'] = `Bearer ${action.payload.data.accessToken}`
						return config
					},
					(error) => {
						return Promise.reject(error)
					}
				)
				localStorage.setItem('TK', JSON.stringify(action.payload.data))
			})
			.addCase(signupAction.rejected, (state, action: PayloadAction<any>) => {
				state.status = REDUX_STATUS.FAILED
				state.error = action.payload
				SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
	}
})

export const authActions = authSlice.actions
export default authSlice.reducer
