import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAllProductsService, getFilterCategoriesService, getProductDetailsService } from "../services/menu.service"
import { IPagination } from "../../../utility/interface/pagination.interface"

export const getAllProductsAction = createAsyncThunk('getAllProductsAction/post', async (data: IPagination, thunkApi) => {
	try {
		const response = await getAllProductsService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getProductDetailsAction = createAsyncThunk('getProductDetailsAction/get', async (id: string, thunkApi) => {
	try {
		const response = await getProductDetailsService(id)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getFilterCategoriesAction = createAsyncThunk('getFilterCategoriesAction/get', async (data: IPagination, thunkApi) => {
  try {
    const response = await getFilterCategoriesService(data);
    return response.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data);
  }
});