import { CartRemoveDto, CartUpdateDto, ICart } from '../common/components/interfaces/cart.interface'
import { Api } from '../resources/api-constants'
import CustomAxios from '../utility/customAxios'

export const addNewCartService = (data: CartUpdateDto) => {
	return CustomAxios.post(`${Api.CREATE_CART}`, data)
}

export const addCartItemService = (id: string, data: CartUpdateDto) => {
	return CustomAxios.put(`${Api.ADD_ITEM}/${id}`, data)
}

export const removeCartItemService = (id: string, data: CartRemoveDto) => {
	return CustomAxios.put(`${Api.REMOVE_ITEM}/${id}`, data)
}

export const updateCartItemService = (id: string, data: ICart) => {
	return CustomAxios.put(`${Api.UPDATE_ITEM}/${id}`, data)
}

export const getCartService = (id: string) => {
	return CustomAxios.get(`${Api.GET_CART}/${id}`)
}
