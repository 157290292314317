import { Api } from '../../../resources/api-constants'
import customAxios from '../../../utility/customAxios'

export const getFavoriteService = () => {
	return customAxios.get(Api.GET_FAVORITE)
}

export const addFavoriteService = (body: {product: string}) => {
	return customAxios.post(Api.ADD_FAVORITE, body)
}
