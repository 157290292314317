import { createSlice } from '@reduxjs/toolkit'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import { getFavoriteAction } from '../actions/favorite.action'
import { IFavorite } from '../interfaces/favorite.interface'

interface DataReducer {
	favorite: IFavorite
	status: REDUX_STATUS
}

const initialState: DataReducer = {
	favorite: {} as IFavorite,
	status: REDUX_STATUS.IDLE
}

const favoriteSlice = createSlice({
	name: 'favorite',
	initialState,
	reducers: {
		setProductsStatus: (state) => {
			state.status = REDUX_STATUS.IDLE
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getFavoriteAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING
			})
			.addCase(getFavoriteAction.fulfilled, (state, action) => {
				state.favorite = action.payload.data
				state.status = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getFavoriteAction.rejected, (state) => {
				state.status = REDUX_STATUS.FAILED
			})
	}
})

export const favoriteActions = favoriteSlice.actions
export default favoriteSlice.reducer
