import { Api } from '../resources/api-constants'
import CustomAxios from '../utility/customAxios'
import { PaginationExtractor } from '../utility/helpers/pagination-extractor';
import { IPagination } from '../utility/interface/pagination.interface';

export const createNewOrderService = (data: any) => {
	return CustomAxios.post(`${Api.ADD_NEW_ORDER}`, data);
};

export const validatePromoCodeService = (data: {promo: string, kitchenId: string}) => {
	return CustomAxios.get(`${Api.VALIDATE_PROMO}?promoCode=${data.promo}&kitchenId=${data.kitchenId}`);
};

export const getUserOrdersService = (data: IPagination) => {
	return CustomAxios.get(`${Api.LIST_ORDERS}${PaginationExtractor(data)}`);
};

export const cancelOrderService = (id: string) => {
	return CustomAxios.put(`${Api.CANCEL_ORDER}/${id}`);
};
