import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	createFirebaseTokenService,
	loginService,
	loginWithGoogleCallBackService,
	signupService,
	updateFirebaseTokenService,
	validateUserLoginService,
} from '../../../services/auth.service';

export const loginAction = createAsyncThunk('loginAction/post', async (data: any, thunkApi) => {
	try {
		const response = await loginService(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const validateUserLoginAction = createAsyncThunk('validateUserLoginAction/post', async (token: string, thunkApi) => {
	try {
		const response = await validateUserLoginService(token);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const signupAction = createAsyncThunk('signupAction/post', async (data: any, thunkApi) => {
	try {
		const response = await signupService(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const createFirebaseTokenAction = createAsyncThunk('createFirebaseTokenAction/post', async (token: string, thunkApi) => {
	try {
		const response = await createFirebaseTokenService(token);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const updateFirebaseTokenAction = createAsyncThunk('updateFirebaseTokenAction/post', async (token: string, thunkApi) => {
	try {
		const response = await updateFirebaseTokenService(token);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
