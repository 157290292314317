
export const PaginationExtractor = (pagination: any): string => {
	let paginationString = '?'
	for (const property in pagination) {
		if (pagination[property]) {
			paginationString += `${property}=${pagination[property]}&`
		}
	}
	return paginationString
}
