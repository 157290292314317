import { createSlice } from '@reduxjs/toolkit'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import {
	getHomeCategoriesAction,
	getNearByKitchensAction,
	getHomeDiscountedProductsAction,
	getHomeOffersAction,
	getHomeTopRatedProductsAction,
	getTopRatedKitchensAction,
	getNewKitchensAction
} from '../actions/home.action'
import { IKitchen } from '../../kitchens/interfaces/kitchen.interface'
import { ICategory } from '../../../common/lookups/interfaces/category.interface'
import { IOffer, IProduct } from '../../../common/interfaces/product.interface'

interface DataReducer {
	categories: Array<ICategory>
	nearByKitchens: Array<IKitchen>
	topRatedKitchens: Array<IKitchen>
	newKitchens: Array<IKitchen>
	discountedProducts: Array<IProduct>
	topRatedProducts: Array<IProduct>
	offers: Array<IOffer>
	categoryStatus: REDUX_STATUS
	nearByKitchenStatus: REDUX_STATUS
	topRatedKitchenStatus: REDUX_STATUS
	newKitchenStatus: REDUX_STATUS
	discountedProductStatus: REDUX_STATUS
	topRatedProductStatus: REDUX_STATUS
	offerStatus: REDUX_STATUS
}

export const initialState: DataReducer = {
	categories: [],
	nearByKitchens: [],
	topRatedKitchens: [],
	newKitchens: [],
	discountedProducts: [],
	topRatedProducts: [],
	offers: [],
	categoryStatus: REDUX_STATUS.IDLE,
	nearByKitchenStatus: REDUX_STATUS.IDLE,
	topRatedKitchenStatus: REDUX_STATUS.IDLE,
	newKitchenStatus: REDUX_STATUS.IDLE,
	discountedProductStatus: REDUX_STATUS.IDLE,
	topRatedProductStatus: REDUX_STATUS.IDLE,
	offerStatus: REDUX_STATUS.IDLE
}

const homeSlice = createSlice({
	name: 'home',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getHomeCategoriesAction.pending, (state) => {
				state.categoryStatus = REDUX_STATUS.PENDING
			})
			.addCase(getHomeCategoriesAction.fulfilled, (state, action) => {
				state.categories = action.payload.data
				state.categoryStatus = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getHomeCategoriesAction.rejected, (state) => {
				state.categoryStatus = REDUX_STATUS.FAILED
			})

			.addCase(getNearByKitchensAction.pending, (state) => {
				state.nearByKitchenStatus = REDUX_STATUS.PENDING
			})
			.addCase(getNearByKitchensAction.fulfilled, (state, action) => {
				state.nearByKitchens = action.payload.data
				state.nearByKitchenStatus = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getNearByKitchensAction.rejected, (state) => {
				state.nearByKitchenStatus = REDUX_STATUS.FAILED
			})

			.addCase(getTopRatedKitchensAction.pending, (state) => {
				state.topRatedKitchenStatus = REDUX_STATUS.PENDING
			})
			.addCase(getTopRatedKitchensAction.fulfilled, (state, action) => {
				state.topRatedKitchens = action.payload.data
				state.topRatedKitchenStatus = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getTopRatedKitchensAction.rejected, (state) => {
				state.topRatedKitchenStatus = REDUX_STATUS.FAILED
			})

			.addCase(getNewKitchensAction.pending, (state) => {
				state.newKitchenStatus = REDUX_STATUS.PENDING
			})
			.addCase(getNewKitchensAction.fulfilled, (state, action) => {
				state.newKitchens = action.payload.data
				state.newKitchenStatus = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getNewKitchensAction.rejected, (state) => {
				state.newKitchenStatus = REDUX_STATUS.FAILED
			})

			.addCase(getHomeTopRatedProductsAction.pending, (state) => {
				state.topRatedProductStatus = REDUX_STATUS.PENDING
			})
			.addCase(getHomeTopRatedProductsAction.fulfilled, (state, action) => {
				state.topRatedProducts = action.payload.data
				state.topRatedProductStatus = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getHomeTopRatedProductsAction.rejected, (state) => {
				state.topRatedProductStatus = REDUX_STATUS.FAILED
			})

			.addCase(getHomeDiscountedProductsAction.pending, (state) => {
				state.discountedProductStatus = REDUX_STATUS.PENDING
			})
			.addCase(getHomeDiscountedProductsAction.fulfilled, (state, action) => {
				state.discountedProducts = action.payload.data
				state.discountedProductStatus = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getHomeDiscountedProductsAction.rejected, (state) => {
				state.discountedProductStatus = REDUX_STATUS.FAILED
			})

			.addCase(getHomeOffersAction.pending, (state) => {
				state.offerStatus = REDUX_STATUS.PENDING
			})
			.addCase(getHomeOffersAction.fulfilled, (state, action) => {
				state.offers = action.payload.data
				state.offerStatus = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getHomeOffersAction.rejected, (state) => {
				state.offerStatus = REDUX_STATUS.FAILED
			})
	}
})

export const homeActions = homeSlice.actions
export default homeSlice.reducer
