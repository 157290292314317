import { Api } from '../resources/api-constants';
import CustomAxios from '../utility/customAxios';
import { PaginationExtractor } from '../utility/helpers/pagination-extractor';
import { IPagination } from '../utility/interface/pagination.interface';

export const getAllGovernoratesService = (query: IPagination) => {
	return CustomAxios.get(`${Api.LOOKUP_GOVERNORATES}${PaginationExtractor(query)}`);
};

export const getAllCitiesService = (id: string) => {
	return CustomAxios.get(`${Api.LOOKUP_CITIES}/${id}`);
};
