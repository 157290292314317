import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
import { useTranslation } from 'react-i18next';

const NotFoundPage: React.FC = () => {
	const {t} = useTranslation()
	return (
		<section className="error-404">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-12">
						<div className="error">
							<img src="/assets/images/error.svg" />
							<h1>{t("Page_Not_Found")}</h1>
							<p>
								{t("How_You_Came_Here")}
								<br />
								{t("Can_Go_Home")}
							</p>
							<Link to={ROUTES.HOMEPAGE} className="home-btn btn-link">
								{t("Go_To_Homepage")}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default NotFoundPage
