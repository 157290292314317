import { Api } from '../resources/api-constants'
import CustomAxios from '../utility/customAxios'
import { PaginationExtractor } from '../utility/helpers/pagination-extractor'

export const loginService = (body: any) => {
	return CustomAxios.post(Api.LOGIN_LOCAL, body)
}

export const loginWithGoogleCallBackService = (data: any) => {
	return CustomAxios.get(Api.LOGIN_VALIDATE + PaginationExtractor(data))
}

export const validateUserLoginService = (token: string) => {
	return CustomAxios.post(Api.LOGIN_VALIDATE, {idToken: token})
}

export const signupService = (body: any) => {
	return CustomAxios.post(Api.SIGNUP_LOCAL, body)
}

export const createFirebaseTokenService = (token: string) => {
  return CustomAxios.post(Api.CREATE_FIREBASE_TOKEN, {firebaseToken: token})
}

export const updateFirebaseTokenService = (token: string) => {
  return CustomAxios.put(Api.UPDATE_FIREBASE_TOKEN, {firebaseToken: token})
}
