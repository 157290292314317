import { createSlice } from '@reduxjs/toolkit'
import { ICity, IGovernorate } from '../interfaces/lookup.interface'
import { getAllCitiesAction, getAllGovernoratesAction } from '../actions/lookup.action'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'

interface DataReducer {
	governorates: Array<IGovernorate>
	cities: Array<ICity>
	governorateStatus: REDUX_STATUS
	cityStatus: REDUX_STATUS
	error: boolean
}

const initialState: DataReducer = {
	governorates: [],
	cities: [],
	governorateStatus: REDUX_STATUS.IDLE,
	cityStatus: REDUX_STATUS.IDLE,
	error: false
}

const lookupSlice = createSlice({
	name: 'lookup',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllGovernoratesAction.pending, (state) => {
				state.governorateStatus = REDUX_STATUS.PENDING
			})
			.addCase(getAllGovernoratesAction.fulfilled, (state, action) => {
				state.governorateStatus = REDUX_STATUS.SUCCEEDED
				state.governorates = action.payload.data
			})
			.addCase(getAllGovernoratesAction.rejected, (state, action: any) => {
				state.governorateStatus = REDUX_STATUS.FAILED
				state.error = action.payload
			})

			.addCase(getAllCitiesAction.pending, (state) => {
				state.cityStatus = REDUX_STATUS.PENDING
			})
			.addCase(getAllCitiesAction.fulfilled, (state, action) => {
				state.cityStatus = REDUX_STATUS.SUCCEEDED
				state.cities = action.payload.data
			})
			.addCase(getAllCitiesAction.rejected, (state, action: any) => {
				state.cityStatus = REDUX_STATUS.FAILED
				state.error = action.payload
			})
	}
})

export const lookupActions = lookupSlice.actions
export default lookupSlice.reducer
