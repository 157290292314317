import React, { createContext, useEffect, useState } from 'react'
import { IUser } from '../modules/profile/interface/User.interface'
import { useAppSelector } from '../store/store'

interface InitialUser {
	user: IUser
}

const initValue: InitialUser = {
	user: {} as IUser
}

const UserContext = createContext(initValue.user)

export const UserProvider = ({ children }: any) => {
	const [user, setUser] = useState(initValue.user)
	const { profile } = useAppSelector((state) => state.profile)

	useEffect(() => {
		if (profile) {
			setUser(profile)
		}
	}, [profile])

	return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export default UserContext
