import { createAsyncThunk } from '@reduxjs/toolkit'
import { cancelSpecialOfferService, createSpecialMealService, getSpecialMealService, selectSpecialOfferService } from '../../../services/special-order.service'
import { SpecialMealDto } from '../dtos/special-meal.dto'

export const getSpecialMealAction = createAsyncThunk('getSpecialMealAction/get', async (id: string, thunkApi) => {
	try {
		const response = await getSpecialMealService(id)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const createSpecialMealAction = createAsyncThunk('createSpecialMealAction/get', async (data: SpecialMealDto, thunkApi) => {
	try {
		const response = await createSpecialMealService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const selectSpecialOfferAction = createAsyncThunk('selectSpecialOfferAction/get', async (data: { specialId: string; kitchenId: string }, thunkApi) => {
	try {
		const response = await selectSpecialOfferService(data.specialId, data.kitchenId)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const cancelSpecialOfferAction = createAsyncThunk('cancelSpecialOfferAction/get', async (specialId: string, thunkApi) => {
	try {
		const response = await cancelSpecialOfferService(specialId)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
