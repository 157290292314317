import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { addCartItemAction, addNewCartAction, getCartAction, removeCartItemAction, updateCartItemAction } from '../actions/cart.action'
import { ICart } from '../../../common/components/interfaces/cart.interface'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const SuccessAlert = withReactContent(Swal)

interface DataReducer {
	cart: ICart
	status: string
}

const initialState: DataReducer = {
	cart: {} as ICart,
	status: REDUX_STATUS.IDLE
}

const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		setCart(state: DataReducer, action: PayloadAction<ICart>) {
			state.cart = action.payload
			state.status = REDUX_STATUS.IDLE
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCartAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING
			})
			.addCase(getCartAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED
				state.cart = action.payload.data
			})
			.addCase(getCartAction.rejected, (state, action: any) => {
				state.status = REDUX_STATUS.FAILED
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})

			.addCase(addNewCartAction.pending, () => {})
			.addCase(addNewCartAction.fulfilled, (state, action) => {
				localStorage.setItem('CT', action.payload.data._id)
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
				state.status = REDUX_STATUS.IDLE
				state.cart = {} as ICart
			})
			.addCase(addNewCartAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})

			.addCase(addCartItemAction.pending, () => {})
			.addCase(addCartItemAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
				state.status = REDUX_STATUS.IDLE
				state.cart = {} as ICart
			})
			.addCase(addCartItemAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})

			.addCase(removeCartItemAction.pending, () => {})
			.addCase(removeCartItemAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
				state.status = REDUX_STATUS.IDLE
				state.cart = {} as ICart
			})
			.addCase(removeCartItemAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})

			.addCase(updateCartItemAction.pending, () => {})
			.addCase(updateCartItemAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
				state.status = REDUX_STATUS.IDLE
				state.cart = {} as ICart
			})
			.addCase(updateCartItemAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
	}
})

export const cartActions = cartSlice.actions
export default cartSlice.reducer
