import { IReviewDto } from '../modules/kitchens/interfaces/review.interface';
import { Api } from '../resources/api-constants';
import CustomAxios from '../utility/customAxios';
import { PaginationExtractor } from '../utility/helpers/pagination-extractor';

export const getAllKitchensService = (query: any) => {
	return CustomAxios.get(`${Api.GET_ALL_KITCHENS}${PaginationExtractor(query)}`);
};

export const getKitchenDetailsService = (id: string) => {
	return CustomAxios.get(`${Api.GET_KITCHEN_DETAILS}/${id}`);
};

export const getKitchenReviewsService = (id: string) => {
	return CustomAxios.get(`${Api.GET_KITCHEN_REVIEWS}/${id}`);
};

export const addKitchenReviewService = (data: IReviewDto) => {
	return CustomAxios.post(`${Api.ADD_REVIEW}`, data);
};