import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthGuard = (props: { children: any }) => {
	const user = localStorage.getItem('TK');

	if (!user) {
		return <Navigate to="/login" replace />;
	} else {
		return props.children;
	}
};

export default AuthGuard;
