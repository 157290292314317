import { createAsyncThunk } from '@reduxjs/toolkit'
import { addFavoriteService, getFavoriteService } from '../services/favorite.service'

export const getFavoriteAction = createAsyncThunk('getFavoriteAction/post', async (_, thunkApi) => {
	try {
		const response = await getFavoriteService()
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const addFavoriteAction = createAsyncThunk('addFavoriteAction/post', async (body: { product: string }, thunkApi) => {
	try {
		const response = await addFavoriteService(body)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
