import { createSlice } from '@reduxjs/toolkit'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { cancelOrderAction, createNewOrderAction, getUserOrdersAction, validatePromoCodeAction } from '../actions/checkout.actions'
import { IPromoCode } from '../interfaces/promo-code.interface'
import { IOrderDto } from '../dto/order.dto'
import { IOrder } from '../interfaces/order.interface'

export const SuccessAlert = withReactContent(Swal)

interface DataReducer {
	order: IOrderDto
	orders: Array<IOrder>
	promoCode: IPromoCode
	status: string
}

const initialState: DataReducer = {
	order: {} as IOrderDto,
	orders: [],
	promoCode: {} as IPromoCode,
	status: REDUX_STATUS.IDLE
}

const checkoutSlice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setPromo: (state, action) => {
			state.promoCode = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserOrdersAction.pending, (state) => {
				state.status = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getUserOrdersAction.fulfilled, (state, action) => {
				state.orders = action.payload.data
				state.status = REDUX_STATUS.SUCCEEDED
			})
			.addCase(getUserOrdersAction.rejected, (state, action: any) => {
				state.status = REDUX_STATUS.SUCCEEDED
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})

			.addCase(createNewOrderAction.pending, () => {})
			.addCase(createNewOrderAction.fulfilled, (_, action) => {
				localStorage.removeItem('CT')
				localStorage.removeItem('SP')
				if(!action.payload.data.isActive){
					localStorage.setItem('OD', JSON.stringify(action.payload.data))
				}
			})
			.addCase(createNewOrderAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})

			.addCase(validatePromoCodeAction.pending, () => {})
			.addCase(validatePromoCodeAction.fulfilled, (state, action) => {
				state.promoCode = action.payload.data
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
			.addCase(validatePromoCodeAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})

			.addCase(cancelOrderAction.pending, () => {})
			.addCase(cancelOrderAction.fulfilled, (_, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
			.addCase(cancelOrderAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
			})
	}
})

export const checkoutActions = checkoutSlice.actions
export default checkoutSlice.reducer
