export const ROUTES = {
	HOMEPAGE: '/',
	LOGIN: '/login',
	SIGNUP: '/signup',
	KITCHENS: '/kitchens',
	KITCHEN_DETAILS: '/kitchen/details/:id',
	MENU: '/menu',
	MENU_DETAILS: '/menu/details/:id',
	CONTACT_US: '/contact-us',
	ABOUT_US: '/about-us',
	HOW_TO_ORDER: '/how-to-order',
	CART: '/cart',
	CHECKOUT: '/checkout',
	PROFILE: '/profile',
	FAVORITE: '/favorite',
	SPECIAL_MEAL: '/special/:id'
}
