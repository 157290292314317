import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	addNewAddressService,
	deleteAddresservice,
	getProfileService,
	getUserAddressService,
	updateAddresservice,
	updatePasswordService,
	updateProfileService,
	uploadImageService
} from '../../../services/profile.service'
import { AddressDto } from '../dto/address.dto'
import { IUser } from '../interface/User.interface'

export const uploadImageAction = createAsyncThunk('uploadImageAction/post', async (data: any, thunkApi) => {
	try {
		const response = await uploadImageService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getProfileAction = createAsyncThunk('getProfileAction/post', async (_, thunkApi) => {
	try {
		const response = await getProfileService()
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const updateProfileAction = createAsyncThunk('updateProfileAction/patch', async (data: any, thunkApi) => {
	try {
		const response = await updateProfileService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const updatePasswordAction = createAsyncThunk('updatePasswordAction/patch', async (data: any, thunkApi) => {
	try {
		const response = await updatePasswordService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getUserAddressAction = createAsyncThunk('getUserAddressAction/post', async (_, thunkApi) => {
	try {
		const response = await getUserAddressService()
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const addNewAddressAction = createAsyncThunk('addNewAddressAction/post', async (data: AddressDto, thunkApi) => {
	try {
		const response = await addNewAddressService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const updateAddressAction = createAsyncThunk('updateAddressAction/post', async (data: AddressDto, thunkApi) => {
	try {
		const response = await updateAddresservice(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const deleteAddressAction = createAsyncThunk('deleteAddresservice/delete', async (id: string, thunkApi) => {
	try {
		const response = await deleteAddresservice(id)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
